
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_936nFy_dOvZzx4fXMFtzjtjAzYrAABrk0BZxkFPQ0zQM4Meta } from "/root/projects/los-web/pages/(inspiratie)/[slug].vue?macro=true";
import { default as inspiratie_45yaC27QSEB0XYqG4A0pfkGA_45pZaqVdRCHFqyxN_45czYkMeta } from "/root/projects/los-web/pages/(inspiratie)/inspiratie.vue?macro=true";
import { default as accessoiresCi5iIRAA2kH_K6XKlypGGlKdc6GvtabigvbNaHLj__YMeta } from "/root/projects/los-web/pages/accessoires.vue?macro=true";
import { default as algemene_45voorwaardenz8rvJ6aNt5DBaB_45VXrTHpiiwNPkJUhDzWb1bUh_Kp7AMeta } from "/root/projects/los-web/pages/algemene-voorwaarden.vue?macro=true";
import { default as annuleringsbeleidIrFLL7uHU91TepjC_eWSm0d4RautqArYE_PEc7Plqx8Meta } from "/root/projects/los-web/pages/annuleringsbeleid.vue?macro=true";
import { default as contactUP0sDorLFTdCWUDIP7i3AK_45wbx4R88BbOIGam5aTATQMeta } from "/root/projects/los-web/pages/contact.vue?macro=true";
import { default as cookiesJnPDIOgJ2I2YA9s8yx8LskodQR168mDz5YgjQvbMnw4Meta } from "/root/projects/los-web/pages/cookies.vue?macro=true";
import { default as index_45rLz2E4zuEVMw8cOlkxA7hg9LsNHOQ7kF8_45VGS9G0awMeta } from "/root/projects/los-web/pages/index.vue?macro=true";
import { default as linksJmD2Q64oSyU_tnLTOW0upJX02ASLAartwoibfMeKwsUMeta } from "/root/projects/los-web/pages/links.vue?macro=true";
import { default as indexN9hiGih49ZHolxfUmoP_p7vHOl24HTWNASgVXOhB8YwMeta } from "/root/projects/los-web/pages/machines/index.vue?macro=true";
import { default as slugFIKVWPVwdbTx3R6P10w8uuWuJj2HJBOpRqvLuzuVMhQMeta } from "/root/projects/los-web/pages/machines/slug.vue?macro=true";
import { default as machinesWae53Q6CM4XsDS1ZpyJWJyrOA3XF2dVPQqm5akdNAKkMeta } from "/root/projects/los-web/pages/machines.vue?macro=true";
import { default as over_45onsW9cmtDr3_k_45bW2KEGXDnUWIrCZmW3yulrIQDsGPtFDwMeta } from "/root/projects/los-web/pages/over-ons.vue?macro=true";
import { default as privacyverklaringlwXri7LmWodS6FICr1QtClDN5VfEicLh4kWhuTjBMnkMeta } from "/root/projects/los-web/pages/privacyverklaring.vue?macro=true";
import { default as reparatiesBID2ReBS2nofnpH1oMNYIKXqL_ejUwvu67diSeufOrkMeta } from "/root/projects/los-web/pages/reparaties.vue?macro=true";
import { default as _91slug_93LCyrBK4ot8iuvtVNgtaX7fnYzWcNmtJuoL8c_45UDtnkAMeta } from "/root/projects/los-web/pages/workshops/[slug].vue?macro=true";
import { default as indexnNkyyD_45o0fGEjHwexfFBRGxDD1u_NJltDkeu1PZgZaMMeta } from "/root/projects/los-web/pages/workshops/index.vue?macro=true";
import { default as workshops41OAZ5k3OkcPZgbB3wUGwuEGLMGw4LeMiSf9dAaTVlgMeta } from "/root/projects/los-web/pages/workshops.vue?macro=true";
import { default as component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8Meta } from "/root/projects/los-web/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8 } from "/root/projects/los-web/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    meta: _91slug_936nFy_dOvZzx4fXMFtzjtjAzYrAABrk0BZxkFPQ0zQM4Meta || {},
    component: () => import("/root/projects/los-web/pages/(inspiratie)/[slug].vue")
  },
  {
    name: "inspiratie",
    path: "/inspiratie",
    component: () => import("/root/projects/los-web/pages/(inspiratie)/inspiratie.vue")
  },
  {
    name: "accessoires",
    path: "/accessoires",
    component: () => import("/root/projects/los-web/pages/accessoires.vue")
  },
  {
    name: "algemene-voorwaarden",
    path: "/algemene-voorwaarden",
    component: () => import("/root/projects/los-web/pages/algemene-voorwaarden.vue")
  },
  {
    name: "annuleringsbeleid",
    path: "/annuleringsbeleid",
    component: () => import("/root/projects/los-web/pages/annuleringsbeleid.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/root/projects/los-web/pages/contact.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/root/projects/los-web/pages/cookies.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/projects/los-web/pages/index.vue")
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/root/projects/los-web/pages/links.vue")
  },
  {
    name: machinesWae53Q6CM4XsDS1ZpyJWJyrOA3XF2dVPQqm5akdNAKkMeta?.name,
    path: "/machines",
    component: () => import("/root/projects/los-web/pages/machines.vue"),
    children: [
  {
    name: "machines",
    path: "",
    component: () => import("/root/projects/los-web/pages/machines/index.vue")
  },
  {
    name: "machines-slug",
    path: "slug",
    component: () => import("/root/projects/los-web/pages/machines/slug.vue")
  },
  {
    name: "machines-type",
    path: "/machines/:type",
    component: () => import("/root/projects/los-web/pages/machines/index.vue")
  },
  {
    name: "machines-brand",
    path: "/machines/:type/:brand",
    component: () => import("/root/projects/los-web/pages/machines/index.vue")
  }
]
  },
  {
    name: "over-ons",
    path: "/over-ons",
    component: () => import("/root/projects/los-web/pages/over-ons.vue")
  },
  {
    name: "privacyverklaring",
    path: "/privacyverklaring",
    component: () => import("/root/projects/los-web/pages/privacyverklaring.vue")
  },
  {
    name: "reparaties",
    path: "/reparaties",
    component: () => import("/root/projects/los-web/pages/reparaties.vue")
  },
  {
    name: workshops41OAZ5k3OkcPZgbB3wUGwuEGLMGw4LeMiSf9dAaTVlgMeta?.name,
    path: "/workshops",
    component: () => import("/root/projects/los-web/pages/workshops.vue"),
    children: [
  {
    name: "workshops-slug",
    path: ":slug()",
    component: () => import("/root/projects/los-web/pages/workshops/[slug].vue")
  },
  {
    name: "workshops",
    path: "",
    component: () => import("/root/projects/los-web/pages/workshops/index.vue")
  }
]
  },
  {
    name: "machine-slug",
    path: "/machines/:type/:brand/:slug",
    component: () => import("/root/projects/los-web/pages/machines/slug.vue")
  },
  {
    name: component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8Meta?.name,
    path: "/winkel/team",
    component: component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8
  },
  {
    name: component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8Meta?.name,
    path: "/winkel",
    component: component_45stubQZrTErUZ8DcIHuTQWtmP2_45a5EaSjGa89M37WoMojMG8
  }
]