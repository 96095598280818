const redirects = [
  {
    from: '/pfaff-expression-720',
    to: '/machines/naaimachines/pfaff/pfaff-quilt-expression-720',
  },
  { from: '/home', to: '/' },
  { from: '/index.html', to: '/' },
  { from: '/privacy', to: '/privacyverklaring' },
  { from: '/bernina-l460', to: '/machines/lockmachines/bernina/bernina-l460' },
  {
    from: '/husqvarna-viking-designer-brilliance-75q',
    to: '/machines/naaimachines/husqvarna/husqvarna-viking-brilliance-75-q',
  },
  {
    from: '/baby-lock-euphoria',
    to: '/machines/covermachines/baby-lock/baby-lock-euphoria',
  },
  {
    from: '/brother-innovis-a16',
    to: '/machines/naaimachines/brother/brother-innov-is-a16',
  },
  { from: '/inspiraties', to: '/inspiratie' },
  {
    from: '/brother-lock-airflow-3000',
    to: '/machines/lockmachines/brother/brother-airflow-3000',
  },
  {
    from: '/brother-scanncut-sdx1250',
    to: '/machines/speciale-machines/brother/brother-scanncut-sdx-1250',
  },
  { from: '/bernina-b335', to: '/machines/naaimachines/bernina/bernina-335' },
  {
    from: '/janome-memory-craft-6700p',
    to: '/machines/naaimachines/janome/janome-memory-craft-6700-p',
  },
  { from: '/speciale-machines', to: '/machines/speciale-machines' },
  {
    from: '/brother-innovis-nv1800q',
    to: '/machines/naaimachines/brother/brother-innov-is-ns-1800-q',
  },
  { from: '/naai-,-borduurmachines', to: '/machines/naai-borduurmachines' },
  {
    from: '/brother-innovis-xe1-stellaire',
    to: '/machines/borduurmachines/brother/brother-innov-is-stellaire-xe-1',
  },
  { from: '/bernette', to: '/machines/alle-machines/bernette' },
  { from: '/covermachines', to: '/machines/covermachines' },
  { from: '/training-creatief', to: '/workshops/creatieve-training' },
  { from: '/cover-lockmachines', to: '/machines/cover-lockmachines' },
  {
    from: '/brother-pr680w',
    to: '/machines/borduurmachines/brother/brother-pr-680-w',
  },
  { from: '/training-covermachine', to: '/workshops/covermachinetraining' },
  {
    from: '/baby-lock-cover-stitch',
    to: '/machines/covermachines/baby-lock/baby-lock-cover-stitch',
  },
  { from: '/training-lockmachine', to: '/workshops/lockmachinetraining' },
  { from: '/juki-mo-664d', to: '/machines/lockmachines/juki/juki-mo-644-d' },
  { from: '/baby-lock', to: '/machines/alle-machines/baby-lock' },
  { from: '/bernina', to: '/machines/alle-machines/bernina' },
  { from: '/lewenstein', to: '/machines/alle-machines/lewenstein' },
  { from: '/workshop-dummies', to: '/workshops/naaien-voor-beginners' },
  { from: '/pfaff', to: '/machines/alle-machines/pfaff' },
  { from: '/juki', to: '/machines/alle-machines/juki' },
  { from: '/juki-mo-644d', to: '/machines/lockmachines/juki/juki-mo-644-d' },
  { from: '/brother', to: '/machines/alle-machines/brother' },
  {
    from: '/husqvarna-viking-amber-air-s600',
    to: '/machines/cover-lockmachines/husqvarna/husqvarna-viking-air-s-600',
  },
  {
    from: '/z--cursus-free-motion-quilten',
    to: '/workshops/beginnercursus-quilten',
  },
  {
    from: '/brother-cs70s',
    to: '/machines/naaimachines/brother/brother-cs-70-s',
  },
  { from: '/janome', to: '/machines/alle-machines/janome' },
  { from: '/workshop-kinder', to: '/workshops/kinderworkshop' },
  { from: '/borduurmachines', to: '/machines/borduurmachines' },
  {
    from: '/husqvarna-viking-epic-980q',
    to: '/machines/naaimachines/husqvarna/husqvarna-viking-epic-980-q',
  },
  { from: '/husqvarna', to: '/machines/alle-machines/husqvarna' },
  { from: '/training-naaimachine', to: '/workshops/naaimachinetraining' },
  { from: '/occasions', to: '/machines/occassions' },
  {
    from: '/brother-innovis-nv2700',
    to: '/machines/naai-borduurmachines/brother/brother-innov-is-nv-2700',
  },
  { from: '/training-workshop-borduur', to: '/workshops/borduurworkshop' },
  { from: '/lockmachines', to: '/machines/lockmachines' },
  { from: '/naaimachines', to: '/machines/naaimachines' },
  { from: '/Cms_System/Privacyverklaring.pdf', to: '/privacyverklaring' },
];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const redirect = redirects.find((r) => r.from === to.path);

  if (redirect) {
    return navigateTo(redirect.to, { redirectCode: 301 });
  }
});
