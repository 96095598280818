<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import 'dayjs/locale/nl';

dayjs.locale('nl');
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
</script>
